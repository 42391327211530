import { BatchContextProvider } from './contexts/BatchContext';
import { AuthContextProvider } from './contexts/AuthContext';
import './App.css';
import Landing from './Landing';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <BatchContextProvider>
          <Landing />
        </BatchContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
