import { createContext, useState, useEffect } from 'react'
import { createClient } from "@supabase/supabase-js";

export const AuthContext = createContext(null)
export const AuthContextProvider = ({ children }) => {
  const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_ANON_KEY } = process.env;
  const [client, setClient] = useState(null);
  const [session, setSession] = useState(null)

  const signOut = async () => { 
    const { error } = await client.auth.signOut(); 
    if (error) console.error('Error logging out:', error.message)
  }
  
  useEffect(() => {
    if (client) {
      client.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
      })
  
      const {
        data: { subscription },
      } = client.auth.onAuthStateChange((_event, session) => {
        setSession(session)
      })
  
      return () => subscription.unsubscribe()
    } else {
      setClient(createClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_ANON_KEY))
    }

  }, [client])
  
  return (
    <AuthContext.Provider value={{ client, session, setSession, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}