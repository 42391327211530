import { createContext, useState, useEffect } from 'react'
import { useAuth } from '../hooks/useAuth'

export const BatchContext = createContext([])
export const BatchContextProvider = ({ children }) => {
  const { client } = useAuth()
  const [batches, setBatches] = useState([])
  const [statuses, setStatuses] = useState([])
  const [eventTypes, setEventTypes] = useState([])

  const deleteBatch = async (id) => {
    await client.from('batches').delete().eq('id', id)
    fetchBatches()
  }

  const fetchBatches = async () => {
    if (client) {
      let user = await client.auth?.getUser();
      if (user.data.user) {
        let { data } = await client
        .from('batches')
        .select("*")
        .eq('user_id', user.data.user.id);
      
        setBatches(data);
      }
    }
  }

  const fetchStatuses = async () => {
    if (client) {
      let { data } = await client
      .from('batch_status')
      .select("*");
    
      setStatuses(data);
    }
  }

  const fetchEventTypes = async () => {
    if (client) {
      let { data } = await client
        .from('batch_event_types')
        .select('*')

      setEventTypes(data)
    }
  }

  useEffect(() => {
    if (client) {
      fetchBatches()
      fetchStatuses()
      fetchEventTypes()
    }
  }, [client])


  return (
    <BatchContext.Provider value={{ batches, setBatches, fetchBatches, statuses, deleteBatch, eventTypes, setEventTypes }}>
      {children}
    </BatchContext.Provider>
  )
}