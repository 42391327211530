import { useState } from 'react'
import { PlusIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import IncubationSlideOut from './IncubationSlideOut'

export default function EmptyIncubationList() {
  const [open, setOpen] = useState(false)

  return (
    <div className="text-center py-28">
      <ExclamationCircleIcon className="w-5 h-5 text-center mx-auto" aria-hidden="true" />
      <IncubationSlideOut open={open} setOpen={setOpen} />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No Incubations</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new incubation batch.</p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
          onClick={() => setOpen(true)}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Batch
        </button>
      </div>
    </div>
  )
}