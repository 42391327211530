import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import EmptyIncubationList from './EmptyIncubationList'
import { useBatches } from './hooks/useBatches'
import VerifyDeleteBatch from './VerifyDeleteBatch'
import ViewBatch from './ViewBatch'

const statusClasses = {
  Ready: 'text-green-700 bg-green-50 ring-green-600/20',
  'In Progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Complete: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/// Calculate the percentage of the time passed
/// @param {string} start - The start date
/// @param {string} end - The end date
/// @returns {string} The percentage of the time passed
function getPercentage(start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)

  // get the number of milliseconds between the two dates
  const difference = endDate - startDate

  // get the number of milliseconds since the start date
  const elapsed = Date.now() - startDate

  // calculate the percentage of the total time that has passed
  const percentage = (elapsed / difference) * 100
  return `${Math.round(Math.min(Math.max(percentage, 0), 100))}%`
}

export default function IncubationList() {
  const { batches, statuses } = useBatches()
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deletingBatch, setDeletingBatch] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [viewingBatch, setViewingBatch] = useState(null);

  return batches.length !== 0 ? (
    <ul className="divide-y divide-gray-100 px-8">
      <VerifyDeleteBatch open={deleteOpen} setOpen={setDeleteOpen} batch={deletingBatch} />
      <ViewBatch open={viewOpen} setOpen={setViewOpen} batch={viewingBatch} />
      {batches.map((project) => {
        let percentagePassed = getPercentage(project.start_time, project.expected_end_time);
        return (
        <li key={project.id} className="items-center py-5">
          <div className="flex justify-between gap-x-6 pb-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{project.name}</p>
                <p
                  className={classNames(
                    statusClasses[statuses[project.status - 1].status_text],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {statuses[project.status - 1].status_text}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Due on {(new Date(project.expected_end_time)).toLocaleDateString()}
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Incubator: {project.incubator}</p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Egg quantity: {project.quantity}</p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <button
                onClick={() => {
                  setViewingBatch(project);
                  setViewOpen(true)
                }}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View batch<span className="sr-only">, {project.name}</span>
              </button>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                          style={{ width: '100%' }}
                        >
                          Edit<span className="sr-only">, {project.name}</span>
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setDeletingBatch(project)
                            setDeleteOpen(true)
                          }}
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                          style={{ width: '100%' }}
                        >
                          Delete<span className="sr-only">, {project.name}</span>
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>

          <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div class="bg-amber-600 text-xs font-medium text-amber-100 text-center p-0.5 leading-none rounded-full" style={{ width: percentagePassed }}>{percentagePassed}</div>
          </div>
        </li>
      )})}
    </ul>
  ) : <EmptyIncubationList />
}