import { SparklesIcon, FlagIcon, ArrowUturnRightIcon, SunIcon, InboxStackIcon, XCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useBatches } from './hooks/useBatches'

export default function BatchFeed({ events }) {
  const { eventTypes } = useBatches()

  const getIcon = (event_type) => {
    switch (event_type) {
        case 1: // start
            return <SparklesIcon className="h-5 w-5 text-white" aria-hidden="true" />
        case 2: // hatch
            return <FlagIcon className="h-5 w-5 text-white" aria-hidden="true" />
        case 3: // turn
            return <ArrowUturnRightIcon className="h-5 w-5 text-white" aria-hidden="true" />
        case 4: // candle
            return <SunIcon className="h-5 w-5 text-white" aria-hidden="true" />
        case 5: // hatchmat
            return <InboxStackIcon className="h-5 w-5 text-white" aria-hidden="true" />
        case 6: // batch end (only happens due to cancellation)
            return <XCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
        default:
            return <ChevronRightIcon className="h-5 w-5 text-white" aria-hidden="true" />
    }
  }

  const getColor = (event_type) => {
    switch (event_type) {
        case 1: // start
            return 'bg-amber-500'
        case 2: // hatch
            return 'bg-green-500'
        case 3: // turn
            return 'bg-gray-500'
        case 4: // candle
            return 'bg-yellow-500'
        case 5: // hatchmat
            return 'bg-gray-500'
        case 6: // batch end (only happens due to cancellation)
            return 'bg-red-500'
        default:
            return 'bg-gray-500'
    }
  }

  return events ? (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {events.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== events.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3 mt-5">
                <div>
                  <span className={`${getColor(event.event_type)} h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`}>
                    {getIcon(event.event_type)}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {eventTypes.find(x => x.id === event.event_type).event_text}{' '}
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                   {(new Date(event.created_at)).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-500">{event.notes}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : <p>No events found for this batch.</p>
}