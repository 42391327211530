import { useState } from 'react'
import { useAuth } from './hooks/useAuth';
import { useBatches } from './hooks/useBatches';

export default function NewIncubation({ setOpen }) {
  const [batchName, setBatchName] = useState(null);
  const [incubatorName, setIncubatorName] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [turning, setTurning] = useState(false);
  const [candling, setCandling] = useState(false);
  const [hatchmat, setHatchmat] = useState(false);
  const [error, setError] = useState(null);

  const { client } = useAuth();
  const { fetchBatches } = useBatches()

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const submit = async () => {
    let start = new Date(Date.now());
    let end = addDays(start, 21);
    let user = await client.auth.getUser();
    const { data, error } = await client
      .from('batches')
      .insert([
        { name: batchName, start_time: start.toISOString(), user_id: user.data.user.id, incubator: incubatorName, quantity, turning_notifications: turning, candling_notifications: candling, hatchmat_notifications: hatchmat, expected_end_time: end.toISOString(), status: 1 },
      ])
      .select()

    await client.from('batch_events').insert([
      { event_type: 1, batch_id: data[0].id, user_id: user.data.user.id, created_at: start.toISOString() }
    ])
    
    if (error) {
      setError(error.message);
      return;
    }

    fetchBatches()
      
    setOpen(false);
  }

  return (
    <div>
      {error && <div className="sm:col-span-6 bg-red-50 p-4 rounded-md text-red-700">{error}</div> }
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
              <label htmlFor="batch-name" className="block text-sm font-medium leading-6 text-gray-900">
                Batch Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-amber-600 sm:max-w-md">
                  <input
                    onChange={(evt) => setBatchName(evt.target.value)}
                    type="text"
                    name="batch-name"
                    id="batch-name"
                    autoComplete="batch-name"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="incubator-name" className="block text-sm font-medium leading-6 text-gray-900">
                Incubator Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-amber-600 sm:max-w-md">
                  <input
                    onChange={(evt) => setIncubatorName(evt.target.value)}
                    type="text"
                    name="incubator-name"
                    id="incubator-name"
                    autoComplete="incubator-name"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="quantity" className="block text-sm font-medium leading-6 text-gray-900">
                Egg Quantity
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-amber-600 sm:max-w-md">
                  <input
                    onChange={(evt) => setQuantity(evt.target.value)}
                    type="number"
                    min={1}
                    name="quantity"
                    id="quantity"
                    autoComplete="quantity"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            We'll always let you know about your incubation batch status, but depending on your incubator style, there are a few other notifications we can send you.
          </p>

          <div className="mt-10 space-y-10">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
              <div className="mt-6 space-y-6">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      onChange={(evt) => setTurning(evt.target.checked)}
                      id="turning"
                      name="turning"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="turning" className="font-medium text-gray-900">
                      Turning Reminders
                    </label>
                    <p className="text-gray-500">If your incubator does not have a rotator, you'll need to manually turn your eggs at least 5 times every 24 hours. Select this notification <strong>only</strong> if your incubator does not auto-rotate your eggs.</p>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      onChange={(evt) => setCandling(evt.target.checked)}
                      id="candling"
                      name="candling"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="candling" className="font-medium text-gray-900">
                      Candling
                    </label>
                    <p className="text-gray-500">Get notified when it's time to candle your eggs (typically at 7-10 days into incubation).</p>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      onChange={(evt) => setHatchmat(evt.target.checked)}
                      id="hatchmat"
                      name="hatchmat"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="hatchmat" className="font-medium text-gray-900">
                      Hatchmat Placement
                    </label>
                    <p className="text-gray-500">Get notified when it's time to place your hatchmat in your incubator, if necessary or relevant for your incubator style (typically at 18-20 days in).</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button onClick={() => setOpen(false)} type="button" className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
           onClick={() => submit()}
          className="rounded-md bg-amber-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
        >
          Start Batch
        </button>
      </div>
    </div>
  )
}