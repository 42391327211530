import IncubationList from "./IncubationList"
import { useAuth } from "./hooks/useAuth";
import Auth from "./Auth"

const Landing = () => {
  const { session, signOut } = useAuth();
  if (!session) return <Auth />
  return (
    <>
      <button onClick={() => signOut()}>Sign out</button>
      <IncubationList />
    </>
  )
  
}

export default Landing