
import { useState } from 'react'
import { useAuth } from './hooks/useAuth';

export default function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const { client } = useAuth();
  
  const validate = () => {
    // if email is not valid, show error message
    if (!email || email === '' || !email.includes('@') || !email.includes('.')) {
        setError('Email is not valid');
        return false;
      }
  
      // if password is not valid, show error message
      if (!password || password === '') {
        setError('Password is not valid');
        return false;
      }

      return true;
  }

  async function signIn() {
    if (validate()) {
        // if email and password are valid, submit form
        await client.auth.signInWithPassword({ email, password }).then(({ error }) => setError(error?.message) );
    }
  };

  async function signUp() {
    if (validate()) {
        // if email and password are valid, submit form
        await client.auth.signUp({ email, password }).then(({ error }) => { setError(error?.message) });
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img className="w-8 h-8 mr-2" src="https://i.pinimg.com/originals/2d/d1/1c/2dd11cb9f7ac107a98b919317fc3f7e7.png" alt="logo" />
            Eggr    
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Sign in to your account
                </h1>
                <div className="space-y-4 md:space-y-6">
                    <div>
                        <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                        <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-amber-600 focus:border-amber-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" onChange={(evt) => setEmail(evt.target.value)} />
                    </div>
                    <div>
                        <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                        <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-amber-600 focus:border-amber-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" onChange={(evt) => setPassword(evt.target.value)}/>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-start">
                            {/* todo: enable remember me and forgot password */}
                            {/* <div className="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-amber-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-amber-600 dark:ring-offset-gray-800" required="" />
                            </div> */}
                            {/* <div className="ml-3 text-sm">
                                <label for="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                            </div> */}
                        </div>
                        {/* <a href="#" className="text-sm font-medium text-amber-600 hover:underline dark:text-amber-500">Forgot password?</a> */}
                    </div>
                    <button onClick={() => signIn()} className="w-full text-white bg-amber-600 hover:bg-amber-700 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800">Sign in</button>
                    <button onClick={() => signUp()} className="w-full text-white bg-amber-600 hover:bg-amber-700 focus:ring-4 focus:outline-none focus:ring-amber-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-amber-600 dark:hover:bg-amber-700 dark:focus:ring-amber-800">Sign Up</button>
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Error!</strong><br />
                            <span className="block sm:inline">{error}</span>
                        </div>
                    )}
                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                        Don’t have an account yet? <a href="#" className="font-medium text-amber-600 hover:underline dark:text-amber-500">Sign up</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}